import React, { useState,useLayoutEffect } from 'react';
import "../Components/Gallery.css";
// import flute from "../Images/flutelogo-removebg-preview.png"
import Tabs from "./Tabs";
import Items from "./Items";
import GalleryData from '../Components/GalleryData';
// import Bounce from 'react-reveal/Bounce';
import Footer from '../Components/Footer';
import {Link } from "react-router-dom";
const Gallery = () => {


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
      });

    const [data, setData] = useState(GalleryData)

    // Store Category in CategoryData 
    const categoryData = GalleryData.map((value) => {
        return value.Category
    });
    ////////////

    const tabsData = ["all Partitions", ...new Set(categoryData)];


    // for all categoryData set 
    const filterCategory = (Category) => {
        if (Category == "all") {
            setData(GalleryData)
            return;
        }
        const filteredData = GalleryData.filter((value) => {
            return value.Category == Category;
        })

        setData(filteredData);

    }


    return (
        <>
 {/* ========= SEO ========== */}
 <title>Frameless Glass Partition service in Thane | Shree ji </title>
      <meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
      <meta
        name="title"
        content="Frameless Glass Partition service in Thane | Shree ji  "
      />
      <meta
        property="og:title"
        content="Frameless Glass Partition service in Thane | Shree ji  "
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://shreejipartitions.in/" />
      <meta
        name="description"
        content="Embrace modern aesthetics with our Frameless Glass Partition Services in Thane. Achieve a seamless and stylish look for your interiors."
      />
      <meta
        property="og:description"
        content="Embrace modern aesthetics with our Frameless Glass Partition Services in Thane. Achieve a seamless and stylish look for your interiors."
      />
      <meta
        property="article:tag"
        content="Best Glass Partition services in Thane"
      />
      <meta property="article:tag" content="Customized Glass Partition services in Thane" />

      <meta
        property="article:tag"
        content="Top Demountable Partition Wholesaler in Thane"
      />
      <meta
        property="article:tag"
        content="Demountable Glass Partition near Thane"
      />
      <meta property="article:tag" content=" Frameless Glass Partition services in Thane" />

      <link
        rel="icon"
        href="../images/"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
   
      <meta
        name="keywords"
        content="Top Demountable Partition Wholesaler in Thane,
        Best Glass Partition services in Thane,
        Customized Glass Partition services in Thane,
        Frameless Glass Partition services in Thane,
        Demountable Glass Partition near Thane "
      />
     
      <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
      <link rel="canonical" href="https://shreejipartitions.in/frameless-glass-partition-services-thane" />
      <meta name="google-site-verification" content="5Zw9pm3yHw3eLjgj-CptPuWgyarylDtCaGYDRvEdUvA" />

      {/* ======== SEO end ======= */}
          {/* =========== Banner section ========= */}
          <div className="AboutUs-Section-1">
        {/* <Header /> */}
        <div className="AboutUs-Section-Banner">
          <div className="Aboutus-banner-text">
            <h1>Frameless Glass Partition service in Thane</h1>
            <div className="Aboutus-banner-text-page">
              <Link to="/">Home</Link>
              <h2>/</h2>
              <h2>Gallery</h2>
            </div>
          </div>
        </div>
      </div>
            {/* ///////////////////////////////// section 1 ////////////////////////////////// */}
            <section class="aboutInfoSection pt-5">
        <div class="container">
          <div class="aboutHomeHead site">
          {/* <div className="bannerLogo">
        <img src={flute} alt=""/>
       </div> */}
            <h1 class="">
            Frameless Glass Partition services in Thane
                </h1>
                {/* <Bounce top cascade> */}
                <h2></h2>
                {/* </Bounce> */}
          
          </div>
        </div>
      </section>
          

            {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

            <div className='Gallery-Section-2'>
                <div className='Gallery-Card'>

                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Tabs filterCategory={filterCategory} tabsData={tabsData} />
                                <Items data={data} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}

export default Gallery