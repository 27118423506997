import React from 'react'
import Carousel from "react-bootstrap/Carousel";
import "../Banner/Home.css";
const Slider1 = () => {
  return (
    <div>
        <>
        <title>Top Demountable Partition Wholesaler in Thane | Shree Ji </title>
      <meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
      <meta
        name="title"
        content="Top Demountable Partition Wholesaler in Thane | Shree Ji"
      />
      <meta
        property="og:title"
        content="Top Demountable Partition Wholesaler in Thane Shree Ji"
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://shreejipartitions.in/" />
      <meta
        name="description"
        content="Discover the Top Demountable Partition Wholesaler in Thane with our premium services you can create versatile spaces effortlessly with Shree ji Partition."
      />
      <meta
        property="og:description"
        content="Discover the Top Demountable Partition Wholesaler in Thane with our premium services you can create versatile spaces effortlessly with Shree ji Partition."
      />
      <meta
        property="article:tag"
        content="Best Glass Partition services in Thane"
      />
      <meta property="article:tag" content="Customized Glass Partition services in Thane" />

      <meta
        property="article:tag"
        content="Top Demountable Partition Wholesaler in Thane"
      />
      <meta
        property="article:tag"
        content="Demountable Glass Partition near Thane"
      />
      <meta property="article:tag" content=" Frameless Glass Partition services in Thane" />

      <link
        rel="icon"
        href="../images/"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
   
      <meta
        name="keywords"
        content="Top Demountable Partition Wholesaler in Thane,
        Best Glass Partition services in Thane,
        Customized Glass Partition services in Thane,
        Frameless Glass Partition services in Thane,
        Demountable Glass Partition near Thane "
      />
     
      <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
      <link rel="canonical" href="https://shreejipartitions.in/" />
      <meta name="google-site-verification" content="5Zw9pm3yHw3eLjgj-CptPuWgyarylDtCaGYDRvEdUvA" />
  {/* =====================================navbar Section ========================================= */}
  <section id=" " className="banner mt-0 pb-5 ">
    <div className="container">
      <div className="row">
        <div className="col-lg-5 order-lg-1 order-2 mt-5" id="">
        <div className="left ">
                      <div className="subtext ">
        <h1>Top Demountable Partition Wholesaler in Thane</h1>
      </div>
        <div className="hometext2 ">
        <h2>Shreeji Partitions</h2>
        <p className="mt-4">Welcome to Shreeji partition, a top demountable partition wholesaler in Thane, a distinguished leader in the glass partition industry. With a relentless commitment to excellence, we bring a wealth of expertise in designing and installing premium glass partitions for a myriad of environments. As we are the top demountable partition wholesaler in Thane, we understand that impeccable design is as crucial as functionality. Our team of seasoned professionals combines technical prowess with an acute sense of aesthetics, delivering bespoke glass solutions tailored to meet the unique needs of each client.We take pride in transforming spaces, be it corporate offices, healthcare facilities, or residential dwellings, into harmonious environments where transparency harmonizes seamlessly with privacy.
      </p> 
          
        </div>
                      
                    
                         
                                        </div>
        
          <p className="bannertext">
            {" "}
            
          </p>
         
          <div className="bannericon d-flex">
            <h5>FOLLOW US:</h5>
            <div>
              <a href="https://www.facebook.com/shreejipartitionsystem/">
                <i className="fab fa-facebook" />
              </a>
            </div>
            <span>
              <a href="https://www.instagram.com/shreejipartitionsystem/
">
                <i className="fab fa-instagram" />
              </a>
            </span>
            <span>
              <a href="">
                <i className="fab fa-google-plus" />
              </a>
            </span>
          </div>
          {/* <button class=" border-animation">About Us</button> */}
        </div>
        <div className="col-lg-7 col-12 order-lg-2 odrer-1 p-0 m-0">
             {/* -----------carousel banner----------------- */}
<div>
      <Carousel>
        <Carousel.Item>
          <div id="thirdSlide" className=""></div>

          <Carousel.Caption>
          <div className="carousel-caption ">
                  <h5>Single Glazed Partition</h5>
                  <p> Full Partition Work.</p>
                </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <div id="firstSlide" className=""></div>
          <Carousel.Caption>
          <div className="carousel-caption ">
                  <h5>Single Glazed Partition</h5>
                  <p>  Full Partition Work.</p>
                </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <div id="secondSlide" className=""></div>

          <Carousel.Caption>
          <div className="carousel-caption ">
                  <h5>Single Glazed Partition</h5>
                  <p> Full Partition Work.</p>
                </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      </div>
        </div>
      </div>
    </div>
  </section>
</>

    </div>
  )
}

export default Slider1