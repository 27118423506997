import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Navigation } from "swiper/modules";
import BestPrice from "../img/icon.png";
import HappyCustomers from "../img/icon.png";
import ExtraStorage from "../img/icon.png";
import "../Components/Testimonial.css";
const Testimonial = () => {
  return (
    <div>
      <section className="service_section">
        <div className="container">
          <div className="left">
            <div className="subtext">
              <h2>Google Reviews</h2>
            </div>
            <div className="hometext2">
              <h2>Testimonials</h2>
            </div>
          </div>
          <div className="row pt-lg-5 pt-2">
            <Swiper
              spaceBetween={30}
              // centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              navigation={true}
              modules={[Autoplay, Navigation]}
              breakpoints={{
                570: {
                  slidesPerView: 1,
                  spaceBetween: 70,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 50,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              className="projSwiper"
            >
         

          
              <SwiperSlide>
                <div className="card2" style={{ padding: 20 }}>
                  <p>
                  Shreeji Partition stands out as the epitome of excellence in restroom design. Their cubicles and operable walls showcase unparalleled craftsmanship and aesthetic appeal, setting a new standard for modern and sophisticated spaces.
                  </p>
                  <h4 className="">Asmita khan</h4>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card2" style={{ padding: 20 }}>
                  <p>
                  Shreeji Partition is undoubtedly one of the leading Indian brands in Glass partition and Restroom cubicle solutions. Their team exemplifies a remarkable combination of expertise and support, making them a standout choice. With their top-notch products and knowledgeable staff, Shreeji Partition has truly set a benchmark in the industry
                  </p>
                  <h4 className="">Anil Raj</h4>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card2" style={{ padding: 20 }}>
                  <p>
                  Exemplary service! As one of the finest demountable glass partition providers in India, their precision and expertise in transforming spaces are truly commendable.
                  </p>
                  <h4 className="">Anil Bakshi</h4>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card2" style={{ padding: 20 }}>
                  <p>
                  Choosing them was a game-changer; their demountable glass partitions redefine elegance and functionality, setting a new standard in architectural solutions.
                  </p>
                  <h4 className="">Anmol Parekh</h4>
                </div>
              </SwiperSlide>
            </Swiper>

            <div className="swiper-pagination" />
          </div>
        </div>
      </section>
      <section className="Home-Section-2">
        <div className="container">
          <div className="HomeSection2-Whyus-text">
            <div className="HomeSection2-Whyus-maintext">
              <h2>Shreeji Works</h2>
            </div>
          </div>

          <div className="Home-Section-WhyUS-Icon-Con">
            <div className="row row-cols-lg-3 row-cols-md-3 row-cols-sm-2 row-cols-2 demo">
              <div className="col">
                <div className="Home-Section-WhyUS-Icon">
                  <div className="Home-Section-WhyUS-Icon-Image-con">
                    <div className="Home-Section-WhyUS-Icon-Image">
                      <img src={BestPrice} alt="Modern design" />
                    </div>
                  </div>
                  <h6>500+<br/>
                    <strong> Projects Completed</strong>
                   
                  </h6>
                </div>
              </div>
              <div className="col">
                <div className="Home-Section-WhyUS-Icon">
                  <div className="Home-Section-WhyUS-Icon-Image-con">
                    <div className="Home-Section-WhyUS-Icon-Image">
                      <img src={HappyCustomers} alt="Luxury interiors" />
                    </div>
                  </div>
                  <h6>15+
                    <strong></strong> Years of Experirence
                  </h6>
                </div>
              </div>
              <div className="col">
                <div className="Home-Section-WhyUS-Icon">
                  <div className="Home-Section-WhyUS-Icon-Image-con">
                    <div className="Home-Section-WhyUS-Icon-Image">
                      <img src={ExtraStorage} alt="Minimalist interiors" />
                    </div>
                  </div>
                  <h6>10+ 
                    <strong></strong> Dealer Pan India
                  </h6>
                </div>
              </div>
                       </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonial;
