import React from 'react'
import "../Components/Footer.css";
import {Link } from "react-router-dom";
import Logo from "../img/Untitled design (57) (1).png";
const Footer = () => {
  return (
    <div>
        <footer className="footer-section">
  <div className="container">
    <div className="footer-cta pt-5 pb-5">
      <div className="row">
        <div className="col-xl-4 col-md-4 mb-30">
          <div className="single-cta">
            <i className="fas fa-map-marker-alt" />
            <div className="cta-text">
              <h4>Find us</h4>
              <span>
              khardigaon , beyond Thane diva , 400612</span>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-4 mb-30">
          <div className="single-cta">
            <i className="fas fa-phone" />
            <div className="cta-text">
              <h4>Call us</h4>
              <span> 8433542937/9799977449</span>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-4 mb-30">
          <div className="single-cta">
            <i className="far fa-envelope-open" />
            <div className="cta-text">
              <h4>Mail us</h4>
              <span>info@shreejipartitions.in</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-content pt-5 pb-5">
      <div className="row">
        <div className="col-xl-4 col-lg-4 mb-50">
          <div className="footer-widget">
            <div className="footer-logo">
              <Link to="/">
                <img
                  src={Logo}
                  className="img"
                  alt="logo"
                />
              </Link>
            </div>
            {/* <div className="footer-text">
              <p>
                Lorem ipsum dolor sit amet, consec tetur adipisicing elit, sed
                do eiusmod tempor incididuntut consec tetur adipisicing
                elit,Lorem ipsum dolor sit amet.
              </p>
            </div> */}
                {/* <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>Contact</h3>
            </div>
            <div className="footer-text mb-25">
              <p>
              8433542937/9799977449
              </p>
            </div>
          
          </div> */}
           
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
          <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>Useful Links</h3>
            </div>
            <ul className="m-0 p-0">
              <li>
                <a href="#">Home</a>
              </li>
             
             
              {/* <li>
                <a href="#">Products</a>
              </li> */}
              <li>
              <Link to="/best-glass-partition-services-thane">About us </Link>
              </li>
              <li>
              <Link to="/customized-glass-partition-services-thane">Products</Link>
              </li>
              <li>
              <Link to="/frameless-glass-partition-services-thane">Gallery</Link>
              </li>
              <li>
              <Link to="/demountable-glass-partition-near-thane">Contact</Link>
             
              </li>
             
              {/* <li>
                <a href="#">Royal Farm </a>
              </li> */}
            
            
            </ul>
          </div>
        
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
          <div className="footer-widget">
            <div className="footer-widget-heading">
              <h3>Address:</h3>
            </div>
            <div className="footer-text mb-25">
              <p>
              Shop no. 05 , celosiya Avenue , next to vighnaharta residency , diva shill road , khardigaon , beyond Thane diva , 400612
              </p>

            </div>
         {/* <div className="footer-social-icon mt-lg-5">
              <span>Follow us</span>
              <a href="https://www.facebook.com/shreejipartitionsystem/">
                <i className="fab fa-facebook-f facebook-bg" />
              </a>
              <a href="https://www.instagram.com/shreejipartitionsystem/">
                <i className="fab fa-instagram twitter-bg" />
              </a>
              <a href="https://maps.app.goo.gl/">
                <i className="fab fa-google-plus-g google-bg" />
              </a>
            </div> */}
          </div>
      
        </div>
      </div>
    </div>
  </div>
  <div className="copyright-area">
    <div className="container">
      <div className="row">
        <div className="col-xl-6 col-lg-6 text-center text-lg-left">
          <div className="copyright-text">
            <p>
              Copyright © 2018, All Right Reserved{" "}
              <a href="/">Shreeji Partition</a>
            </p>
            <p>
              Developed By &nbsp;
              <a href="https://skdm.in/">Shree Krishna Digital Marketing</a>
            </p>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
          <div className="footer-menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
              <Link to="/best-glass-partition-services-thane">About us </Link>
              </li>
              <li>
              <Link to="/customized-glass-partition-services-thane">Products</Link>
              </li>
              <li>
              <Link to="/frameless-glass-partition-near-thane">Gallery</Link>
              </li>
              <li>
              <Link to="/demountable-glass-partition-near-thane">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
{/* <a href="#0" className="cd-top cd-is-visible">
  Top
</a> */}

    </div>
  )
}

export default Footer