import React from 'react'
import About from "../Components/Banner/About";
import Slider from "../Components/Banner/Slider1";
import Products from "../Components/Banner/Products";
import Whychooseus from '../Components/Whychooseus';
import Testimonial from '../Components/Testimonial';
import Footer from "../Components/Footer";
const Home = () => {
  return (
    <div>
      {/* ========= SEO ========== */}
 <title>Top Demountable Partition Wholesaler in Thane | Shree ji  </title>
      <meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
      <meta
        name="title"
        content="Top Demountable Partition Wholesaler in Thane | Shree ji   "
      />
      <meta
        property="og:title"
        content="Top Demountable Partition Wholesaler in Thane | Shree ji "
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://shreejipartitions.in/" />
      <meta
        name="description"
        content="Discover the Top Demountable Partition Wholesaler in Thane with our premium services you can create versatile spaces effortlessly with Shree ji Partition."
      />
      <meta
        property="og:description"
        content="Discover the Top Demountable Partition Wholesaler in Thane with our premium services you can create versatile spaces effortlessly with Shree ji Partition."
      />
      <meta
        property="article:tag"
        content="Best Glass Partition services in Thane"
      />
      <meta property="article:tag" content="Customized Glass Partition services in Thane" />

      <meta
        property="article:tag"
        content="Top Demountable Partition Wholesaler in Thane"
      />
      <meta
        property="article:tag"
        content="Demountable Glass Partition near Thane"
      />
      <meta property="article:tag" content=" Frameless Glass Partition services in Thane" />

      <link
        rel="icon"
        href="../images/"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
   
      <meta
        name="keywords"
        content="Top Demountable Partition Wholesaler in Thane,
        Best Glass Partition services in Thane,
        Customized Glass Partition services in Thane,
        Frameless Glass Partition services in Thane,
        Demountable Glass Partition near Thane "
      />
     
      <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
      <link rel="canonical" href="https://shreejipartitions.in/"/>


      {/* ======== SEO end ======= */}
        <Slider></Slider>

        <Whychooseus></Whychooseus>
        <About></About>
        <Products></Products>
       
        <Testimonial></Testimonial>
        <Footer/>
    </div>
  )
}

export default Home
