import React from "react";

import "../Components/AboutUs.css";
import { Link } from "react-router-dom";
import mission from "../img/double glaze partition/WhatsApp Image 2024-01-04 at 10.57.26 PM.webp"
import vision from "../img/singleglazepartition/WhatsApp Image 2024-01-04 at 10.55.06 PM.webp";
import values from "../img/double glaze partition/WhatsApp Image 2024-01-04 at 10.58.47 PM.webp";

import Footer from "../Components/Footer";


//////////////// Why Unico Icons //////////////////

import BestPrice from "../img/icon.png";
import HappyCustomers from "../img/icon.png";
import ExtraStorage from "../img/icon.png";


// ========= About Img =========
import About2 from "../img/c1.webp";
import About1 from "../img/c2.webp";
// Import Swiper React components


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules


const AboutUs = () => {
  return (
    <>
      <title>Best Glass Partition services in Thane | Shree ji </title>
      <meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
      <meta
        property="og:title"
        content="Best Glass Partition services in Thane | Shree ji "
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://shreejipartitions.in/" />

      <meta
        property="og:description"
        content="Transform your space with the Best Glass Partition Services in Thane. Our expert solutions blend aesthetics and functionality seamlessly. "
      />
      <meta
        property="article:tag"
        content="Best Glass Partition services in Thane"
      />
      <meta property="article:tag" content="Customized Glass Partition services in Thane" />

      <meta
        property="article:tag"
        content="Top Demountable Partition Wholesaler in Thane"
      />
      <meta
        property="article:tag"
        content="Demountable Glass Partition near Thane"
      />
      <meta property="article:tag" content=" Frameless Glass Partition services in Thane" />

      <link
        rel="icon"
        href="../images/"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="Transform your space with the Best Glass Partition Services in Thane. Our expert solutions blend aesthetics and functionality seamlessly."
      />
      <meta
        name="keywords"
        content="Top Demountable Partition Wholesaler in Thane,
        Best Glass Partition services in Thane,
        Customized Glass Partition services in Thane,
        Frameless Glass Partition services in Thane,
        Demountable Glass Partition near Thane "
      />
      <meta
        name="title"
        content="Best Glass Partition services in Thane | Shree ji "
      />
      <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
      <link rel="canonical" href="https://shreejipartitions.in/best-glass-partition-services-thane" />
      <meta name="google-site-verification" content="5Zw9pm3yHw3eLjgj-CptPuWgyarylDtCaGYDRvEdUvA" />
    {/* =========== Banner section ========= */}
      <div className="AboutUs-Section-1">
        {/* <Header /> */}
        <div className="AboutUs-Section-Banner">
          <div className="Aboutus-banner-text">
            <h1>Best Glass Partition services in Thane</h1>
            <div className="Aboutus-banner-text-page">
              <Link to="/">Home</Link>
              <h2>/</h2>
              <h2>About</h2>
            </div>
          </div>
        </div>
      </div>
      {/* =========== About Section =========== */}
      <section className="about-section">
        <div className="container">
          
          <div className="row">
            <div className="aboutImg col-xl-6 col-lg-6">
              <div className="home-about">
                <div className="img1">
                  <img src={About2} alt="Best Glass Partition services" />
                </div>
                <div className="img2">
                  <img src={About1} alt="Glass Partition" />
                </div>
              </div>
            </div>
            <div className="content-column col-lg-6">
            <div className="left">
      
        <div className="hometext2">
        
          <h2 className="">Modern Partitions</h2>
          <p>
          Welcome to Shreeji partition, a best glass partition service in Thane, a distinguished leader in the glass partition industry. With a relentless commitment to excellence, we bring a wealth of expertise in designing and installing premium glass partitions for a myriad of environments. As we are the best glass partition service in Thane we understand that impeccable design is as crucial as functionality. Our team of seasoned professionals combines technical prowess with an acute sense of aesthetics, delivering bespoke glass solutions tailored to meet the unique needs of each client.We take pride in transforming spaces, be it corporate offices, healthcare facilities, or residential dwellings, into harmonious environments where transparency harmonizes seamlessly with privacy.

          </p>
          <p></p>
          
        </div>
        </div>
            </div>
          </div>
        </div>
      </section>
      
      <>
  {/* ============================== home process ============================= */}
  <section className="border-top" id="defaultProcess">
    <div className="container">
    
      <div className="row">
        <div className="col-12 ps-lg-7">
          <div className="row align-items-end">
            <div className="col-lg-6 order-lg-2 text-center">
              <div className="mission ">
              <img
                className="img-fluid"
                src={mission}
                alt="Glass Partition"
              
              />
              </div>
             
            </div>
            <div className="col-lg-6 border-start border-bottom">
              <div className="process-item ms-6 ms-sm-8 ms-lg-6">
                <span className="process-item-number">01</span>
                <h3>Our Mission</h3>
                <p>
                Our mission is to redefine interior spaces by seamlessly integrating aesthetics with functionality through cutting-edge glass partition solutions. Committed to creating environments that inspire collaboration and enhance productivity, we strive to be the premier choice for businesses seeking modern, elegant, and customizable partitioning options.

                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 border-end border-bottom text-center p-4">
              <img
                className="img-fluid"
                src={vision}
                alt="glass partition"
                
              />
            </div>
            <div className="col-lg-6 mt-4 mb-8 my-lg-8">
              <div className="process-item ms-6 ms-sm-8 ms-lg-6">
                <span className="process-item-number ">02</span>
                <h3>Our Vision</h3>
                <p>
                We envision creating modern, open spaces that inspire collaboration and elevate aesthetics. With a commitment to quality and design, we strive to redefine the way spaces are experienced, seamlessly integrating functionality with elegance in every glass partition we install.

                </p>
                {/* <a class="btn btn-outline-dark btn-sm mt-3" href="#!">View Methods</a> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 order-lg-2 text-center align-self-center p-5">
             <div className="valueImg">

           
              <img
                className=""
                src={values}
                alt="Glass partition service"
          
              />
                </div>
            </div>
            <div className="col-lg-6 border-start border-bottom py-lg-8 mt-4 mt-lg-0 mb-8 mb-lg-0">
              <div className="process-item ms-6 ms-sm-8 ms-lg-6">
                <span className="process-item-number">03</span>
                <h3>Our Values</h3>
                <p>
                As the best glass partition service in Thane, our values form the foundation of our commitment to excellence. Integrity guides our every interaction, ensuring transparent and honest relationships with clients and partners. Innovation fuels our passion for cutting-edge designs, pushing the boundaries of what glass partitions can achieve.
 
                </p>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  </section>
</>

      {/* ========== Section Four ========== */}
      <section className="Home-Section-2">
        <div className="container">
          <div className="HomeSection2-Whyus-text">
            <div className="HomeSection2-Whyus-maintext">
              <h2>Shreeji Works</h2>
            </div>
          </div>

          <div className="Home-Section-WhyUS-Icon-Con">
            <div className="row row-cols-lg-3 row-cols-md-3 row-cols-sm-2 row-cols-2 demo">
              <div className="col">
                <div className="Home-Section-WhyUS-Icon">
                  <div className="Home-Section-WhyUS-Icon-Image-con">
                    <div className="Home-Section-WhyUS-Icon-Image">
                      <img src={BestPrice} alt="Shreeji Patitions" />
                    </div>
                  </div>
                  <h6>500+<br/>
                    <strong> Projects Completed</strong>
                   
                  </h6>
                </div>
              </div>
              <div className="col">
                <div className="Home-Section-WhyUS-Icon">
                  <div className="Home-Section-WhyUS-Icon-Image-con">
                    <div className="Home-Section-WhyUS-Icon-Image">
                      <img src={HappyCustomers} alt="Glass Partition" />
                    </div>
                  </div>
                  <h6>15+
                    <strong></strong> Years of Experirence
                  </h6>
                </div>
              </div>
              <div className="col">
                <div className="Home-Section-WhyUS-Icon">
                  <div className="Home-Section-WhyUS-Icon-Image-con">
                    <div className="Home-Section-WhyUS-Icon-Image">
                      <img src={ExtraStorage} alt="Partition Service" />
                    </div>
                  </div>
                  <h6>10+ 
                    <strong></strong> Dealer Pan India
                  </h6>
                </div>
              </div>
                       </div>
          </div>
        </div>
      </section>
     
      <Footer />
    </>
  );
};

export default AboutUs;
