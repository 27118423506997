import React from "react";

const Whychooseus = () => {
  return (
    <div>
      <section className="why" id="defaultServices">
        <div className="container">
      
              <div className="left ">
                <div className="subtext">
                  <h2>Why Choose Us</h2>
                </div>
                <div className="hometext2">
                  <h2>Features</h2>
                
               
              </div>
            </div>
          <div className="row justify-content-center pt-lg-5 pt-2">
           
            <div className="col-lg-6 p-2">
              <div className="d-flex">
                <div className="bannericon">
                  <img
                    src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/icons/multi-purpose.svg"
                    alt="office flooring"
                  />
                </div>
                <div className="texticon">
                  <div className="servicestext">
                    <h4>110k Sq. Ft. Off-the-Shelf Availability</h4>
                    <p>
                     
As the top demountable partition wholesaler in Thane we are your premier destination for state-of-the-art glass partitions with an impressive off-the-shelf availability spanning 110k square feet. As industry leaders, we take pride in offering a vast and readily accessible inventory, ensuring swift and efficient solutions for your partition needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-2">
              <div className="d-flex">
                <div className="bannericon">
                  <img
                    src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/icons/multi-purpose.svg"
                    alt="office flooring"
                  />
                </div>
                <div className="texticon">
                  <div className="overflow-hidden">
                    <h4>Smart Finishing</h4>
                  </div>
                  <div className="overflow-hidden">
                    <p>
                    Our commitment goes beyond conventional glass partitions. We specialize in providing smart finishing touches that elevate your space to new heights of modernity and efficiency. From integrated lighting systems to customizable smart glass options, we offer a range of solutions designed to enhance both aesthetics and functionality.

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-2">
              <div className="d-flex">
                <div className="bannericon">
                  <img
                    src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/icons/multi-purpose.svg"
                    alt=""
                  />
                </div>
                <div className="texticon">
                  <div className="overflow-hidden">
                    <h4>Wide Range of Color options</h4>
                  </div>
                  <div className="overflow-hidden">
                    <p>
                    As a top demountable partition wholesaler in Thane we take pride in offering a vast array of color options to suit every taste and style. From bold hues to subtle tones, discover the perfect palette to infuse vibrancy and sophistication into your spaces. Elevate your design vision with our diverse and customizable glass partitions.

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-2">
              <div className="d-flex">
                <div className="bannericon">
                  <img
                    src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/icons/multi-purpose.svg"
                    alt="office flooring"
                  />
                </div>
                <div className="texticon">
                  <div className="overflow-hidden">
                    <h4>Acoustical Designed frames</h4>
                  </div>
                  <div className="overflow-hidden">
                    <p>
                    We are your destination for cutting-edge glass partitions with acoustically designed frames. We specialize in creating environments where aesthetics meet acoustic excellence. Our frames are meticulously crafted to seamlessly integrate with the glass, ensuring not just visual appeal but also optimal sound performance.

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-2">
              <div className="d-flex">
                <div className="bannericon">
                  <img
                    src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/icons/multi-purpose.svg"
                    alt="office flooring"
                  />
                </div>
                <div className="texticon">
                  <div className="overflow-hidden">
                    <h4>Minimalist Design</h4>
                  </div>
                  <div className="overflow-hidden">
                    <p>
                    We specialize in minimalist design for glass partitions, redefining spaces with clean lines and understated elegance. Our commitment to simplicity is reflected in every detail, offering a seamless blend of aesthetics and functionality. Explore a refined approach to interior design with our minimalist glass partitions, enhancing spaces with timeless and contemporary appeal.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-2">
              <div className="d-flex">
                <div className="bannericon">
                  <img
                    src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/icons/multi-purpose.svg"
                    alt="office flooring"
                  />
                </div>
                <div className="texticon">
                  <div className="overflow-hidden">
                    <h4>Fire resistance</h4>
                  </div>
                  <div className="overflow-hidden">
                    <p>
                    We are your trusted partner for safety and style. We specialize in providing fire-resistant glass partitions, combining functionality with aesthetic appeal. Our commitment to safety is evident in the precision-engineered materials that offer robust fire resistance without compromising on design. Create secure and stylish spaces with our innovative fire-resistant glass partitions.

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-2">
              <div className="d-flex">
                <div className="bannericon">
                  <img
                    src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/icons/multi-purpose.svg"
                    alt="office flooring"
                  />
                </div>
                <div className="texticon">
                  <div className="overflow-hidden">
                    <h4>Competitive prices</h4>
                  </div>
                  <div className="overflow-hidden">
                    <p>
                    We take pride in offering competitive prices without compromising on the excellence of our glass partitions. Discover a perfect blend of cost-effectiveness and top-notch craftsmanship with us.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-2">
              <div className="d-flex">
                <div className="bannericon">
                  <img
                    src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/icons/multi-purpose.svg"
                    alt="office flooring"
                  />
                </div>
                <div className="texticon">
                  <div className="overflow-hidden">
                    <h4>Value for money</h4>
                  </div>
                  <div className="overflow-hidden">
                    <p>
                    A top demountable partition wholesaler in Thane, we redefine value for money. Committed to delivering exceptional quality at affordable prices, we prioritize your satisfaction. With a focus on durability, aesthetics, and cost-effectiveness, we ensure every penny spent brings you enduring value.

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-2">
              <div className="d-flex">
                <div className="bannericon">
                  <img
                    src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/icons/multi-purpose.svg"
                    alt="office flooring"
                  />
                </div>
                <div className="texticon">
                  <div className="overflow-hidden">
                    <h4>High quality</h4>
                  </div>
                  <div className="overflow-hidden">
                    <p>
                    With an unwavering commitment to high quality, we meticulously craft glass partitions that redefine your space. Our attention to detail, premium materials, and skilled craftsmanship ensure unparalleled durability and aesthetics.

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-2">
              <div className="d-flex">
                <div className="bannericon">
                  <img
                    src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/icons/multi-purpose.svg"
                    alt="office flooring"
                  />
                </div>
                <div className="texticon">
                  <div className="overflow-hidden">
                    <h4>
                      Great flexibility
                      {/* Customer driven innovation

Supervision and installation on demand */}
                    </h4>
                  </div>
                  <div className="overflow-hidden">
                    <p>
                    We pride ourselves on offering great flexibility in design and functionality. Whether you seek dynamic office layouts or stylish home divisions, our adaptable glass partitions cater to your unique needs. Experience the freedom to shape your space with innovation and style, making us your go-to choice for a seamlessly flexible solution.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Whychooseus;
