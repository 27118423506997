import React from 'react'
import "../Banner/About.css";
import abt from "../Banner/img/Untitled-10.jpg"
import {Link } from "react-router-dom";
const About = () => {
  return (
    <div>
         {/* <!-- ===================================  text with  images =================================== --> */}
         <section className="aboutSec pt-5">
  <div className="container">
    <div className="row">
      
    
      <div className="col-lg-7 col-12 order-lg-2  order-1 ">
        <div className="img ">
          <img
          className='img-fluid'
            src={abt}
            alt="office flooring"
          />
        </div>
      </div>
      <div className="col-lg-5 order-lg-3 order-3">
      <div className="left">
      <div className="subtext">
        <h2>Our Principles</h2>
      </div>
        <div className="hometext2">
        
          <h2 className="">A sketch of each detail</h2>
          <p>
          At the core of our ethos is a commitment to quality craftsmanship. We take pride in meticulously crafting glass partitions with precision, utilizing high-quality materials that adhere to stringent industry standards. As the best glass partition service in Thane, our dedication to custom solutions ensures that each design is tailored to the unique needs of our clients, striking a perfect balance between functionality and aesthetic appeal. Embracing environmental responsibility, we prioritize sustainability through the use of eco-friendly materials and processes, minimizing our ecological impact.

          </p>
          
        </div>
        </div>
        <div className="row justify-content-center pt-5">
  <div className="col-12 col-md-6 col-lg-6">
    <div className="planimg ">
      <img
        className="mb-3"
        src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/icons/package1.svg"
        alt=""
      
        height={70}
      />
        <div className="plan-head">
     <h2>3D Autocad</h2>
    </div>
    <div className="plan-detail">
      <p>
      3D sketch of each detail
       
      </p>
    </div>
    </div>
  
   
  </div>
  <div className="col-12 col-md-6 col-lg-6">
    <div>
    <div className="planimg">
      <img
        className="mb-3"
        src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/icons/package2.svg"
        alt=""
       
        height={70}
      />
       <div className="plan-head">
      <h2>Customized Project</h2>
    </div>
    </div>
   
    <div className="plan-detail">
      <p>
      We customize to create individual design.
      </p>
    </div>
    </div>
  </div>
  
</div>

      </div>
    </div>
  </div>
</section>
{/* <div className="backBanner"></div> */}

 
    </div>
  )
}

export default About