
import { React, useLayoutEffect,useState } from "react";
import { useLocation,useParams } from "react-router-dom";
import Footer from "./Footer";
import "../Components/Doctors.css";
import abt from "../Components/Banner/img/c3.jpg";
import Site from "../Components/Site";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Nielsen from "../Components/Banner/img/Untitled-1.jpg";
const Details = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
      });
      const search = useLocation();
      const { id } = useParams();
      const [serviceData, setServiceData] = useState(Site);
    
      const FData = serviceData.find((e) => e.id === id);
  return (
    <div>
        {/* =========== Banner section ========= */}
      <div className="AboutUs-Section-1">
        {/* <Header /> */}
        <div className="AboutUs-Section-Banner">
          <div className="Aboutus-banner-text">
            <h1>Products</h1>
            <div className="Aboutus-banner-text-page">
              <Link to="/">Home</Link>
              <h2>/</h2>
              <h2>Products</h2>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- ===================================  text with  images =================================== --> */}
      <section className="pt-5">
        <div className="container">
          <div className="row">
         
      
        {/* {Site.map((elem) => {
              const { id, Category,Pspec,
              Desc, Material, Surface,Length,Standard, Gauge,Track1,
              Track2,
              Track3,
              Track4,
              Track5,
              Track6,
               } = elem;
              return (
                <> */}
            <div className="col-lg-6 col-md-12" >
              <div className="">
                <img className="img-fluid" src={abt} alt="office flooring" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="left">
                <div className="subtext">
                  <h2>Product</h2>
                </div>
                <div className="hometext2">
                  <h2 className="">   Gnat 25 series 25mmx25mm</h2>
<span >(80mm/100mm Double Glaze Partition System)</span><br/>
                  <p>The Ultra Max demountable office partition provides excellent acoustic values. It is based on a 80 x 30 mm glazing seal and bead with a selection of door frame options.
                  
                  </p>
                </div>
              </div>
              {/* <div className="row justify-content-center pt-5">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="planimg ">
                    <img
                      className="mb-3"
                      src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/icons/package1.svg"
                      alt=""
                      height={70}
                    />
                    <div className="plan-head">
                      <Link to="/Aboutus">
                        {" "}
                        <h2>3D Autocad</h2>
                      </Link>
                    </div>
                    <div className="plan-detail">
                      <p>
                        However, concrete floors might be related to cool,
                        modern spaces,
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <div>
                    <div className="planimg">
                      <img
                        className="mb-3"
                        src="https://prium.github.io/twbs-sparrow/v2.0.2/assets/img/icons/package2.svg"
                        alt=""
                        height={70}
                      />
                      <div className="plan-head">
                        <h2>Customized Project</h2>
                      </div>
                    </div>

                    <div className="plan-detail">
                      <p>
                        About business concrete floors, there are a few choices
                        to browse.
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div className="col-lg-6 col-md-12 mt-lg-5 mt-2">
              <div className="">
                <img className="img-fluid" src={abt} alt="office flooring" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 mt-lg-5 mt-2">
              <div className="">
                <img className="img-fluid" src={abt} alt="office flooring" />
              </div>
            </div> */}
            <div className="col-lg-6 col-md-6 col-12 mt-lg-5 mt-2">
            <div className="docDetail">
              <div class="row rowspace">
                <div class="col-12">
                  <div class="Specialty">
                    <span>
                      <b>Material: &nbsp;</b>
                    </span>
                    <span class="description">
                    Aluminium
                    </span>
                  </div>
                </div>
              </div>
              <div class="row rowspace">
                <div class="col-12">
                  <div class="Specialty">
                    <b>Surface Finish

: &nbsp;</b> <span class="description">
Anodized/Powder Coated/Brush/SS Finish
                    </span>
                  
                  </div>
                </div>
              </div>
              <div class="row rowspace">
                {/* <div class="col-sm-4 ">
                       
                    </div> */}
                <div class="col-12">
                  <div class="Specialty">
                    <span>
                      <b>Length Size: &nbsp;</b>
                    </span>
                    <span class="description">
                    3000 mm
                    </span>
                  </div>
                </div>
              </div>
              <div class="row rowspace">
                {/* <div class="col-sm-4 ">
                        <div class="Specialty">
                            <span>Training</span>
                        </div>
                    </div> */}
                <div class="col-12">
                  <div class="Specialty">
                    <span>
                      <b>Standard Size





: &nbsp;</b>
                    </span>
                    
                    <span class="description">
                    80 x 30 mm
                    </span>
                  </div>
                </div>
              </div>
             
              <div class="row rowspace">
              
                <div class="col-12">
                  <div class="Specialty">
                    <span>
                      <b>Gauge: &nbsp;</b>
                    </span>
                    <span class="description">
                    2 mm (approx)
                    </span>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12  mt-lg-5 mt-2">
            <div className="docDetail">
            
              <div class="row rowspace">
                <div class="col-12">
                  <div class="Specialty">
                 <span>  <b>Standard Track Section: &nbsp;</b></span> 
                    <ul>
                      <li className="rowspace">
                      80mm & 30mm 
                      </li>
                      <li className="rowspace">
                      Slim in design,the standard partition suite can be used with any stud thickness                      </li>{" "}
                      <li className="rowspace">Comfortable glass thickness of 10 mm </li>
                      <li className="rowspace">
                      Partition height offered up to 3000 mm.
                      </li>
                      <li className="rowspace">
                      Double glazed system
                      </li>
                      {/* <li className="">
                     {Track4}
                      </li> */}
                    </ul>
                   
                  </div>
                </div>
              </div>
           
            
            </div>
          </div>
{/* 
       
            </>
        );
      })} */}
         <section className="recently-project-style2-area" id="recent">
        <div className="container">
        <div className="left ">
                <div className="subtext">
                  <h2>Related</h2>
                </div>
                <div className="hometext2">
                  <h2>Projects</h2>
                
               
              </div>
            </div>
          <div className="row pt-lg-5 pt-2">
            <div className="col-xl-12">
              <Swiper
                spaceBetween={30}
                // centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
                breakpoints={{
                  570: {
                    slidesPerView: 1,
                    spaceBetween: 70,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
                className="projSwiper"
              >
                <SwiperSlide>
                  <Link to="/Projects">
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={Nielsen} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                          
                          <h3>Gnat 25 series </h3>
                          <span>(25mmx25mm)</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link to="/Projects">
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={Nielsen} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                     
                          <h3>Luna 45 series</h3>
                          <span> (45mmx20mm)</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>

                <SwiperSlide>
                  <Link to="/Projects">
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={Nielsen} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                          {/* <span>Keva Site </span> */}
                          <h3>Apex 45 premium series</h3>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link to="/Projects">
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={Nielsen} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                        
                          <h3>Phoenix 75 series</h3>
                          <span>(75mmx20mm)</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link to="/Projects">
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={Nielsen} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                         
                          <h3>Cosmic 102 series</h3>
                          <span>(102mmx20mm)</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link to="/Projects">
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={Nielsen} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                          {/* <span>J B Nagar</span> */}
                          <h3>Phoenix altra 80 series DG</h3>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link to="/Projects">
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={Nielsen} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                       
                          <h3>Alpha altra Maxx 100 series</h3>
                          <span>(100mmx25mm)</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                
              </Swiper>
            </div>
          </div>
        </div>
      </section>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

    


export default Details