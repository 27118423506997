import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import abt from "../Banner/img/Untitled-10.jpg"
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import "../Banner/Product.css";

import {Link } from "react-router-dom";
import gnat from "./img/gnat.webp";
import cosmic from "./img/cosmic.jpg";
import phoenix1 from "./img/phoenix75.jpeg";
import phoenixultra from "./img/phoenix altra.jpeg";
import luna from "./img/luna.webp";
import phoenix from "./img/phoenix.webp";
import apex from "./img/apex.webp";
const Products = () => {
  return (
    <div>
        <title>Customized Glass Partition service in Thane | Shree ji  </title>
      <meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
      <meta
        name="title"
        content="Top DemountabCustomized Glass Partition service in Thane | Shree ji "
      />
      <meta
        property="og:title"
        content="Customized Glass Partition service in Thane | Shree ji "
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://shreejipartitions.in/" />
      <meta
        name="description"
        content="Elevate your space with bespoke elegance through our Customized Glass Partition Services in Thane. Tailored solutions for unique interiors, combining style ."
      />
      <meta
        property="og:description"
        content="Elevate your space with bespoke elegance through our Customized Glass Partition Services in Thane. Tailored solutions for unique interiors, combining style ."
      />
      <meta
        property="article:tag"
        content="Best Glass Partition services in Thane"
      />
      <meta property="article:tag" content="Customized Glass Partition services in Thane" />

      <meta
        property="article:tag"
        content="Top Demountable Partition Wholesaler in Thane"
      />
      <meta
        property="article:tag"
        content="Demountable Glass Partition near Thane"
      />
      <meta property="article:tag" content=" Frameless Glass Partition services in Thane" />

      <link
        rel="icon"
        href="../images/"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
   
      <meta
        name="keywords"
        content="Top Demountable Partition Wholesaler in Thane,
        Best Glass Partition services in Thane,
        Customized Glass Partition services in Thane,
        Frameless Glass Partition services in Thane,
        Demountable Glass Partition near Thane "
      />
     
      <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
      <link rel="canonical" href="https://shreejipartitions.in/customized-glass-partition-services-thane" />
         {/* <--------------------------------workprocess--------------------------> */}
         {/* <div className="backBanner"></div> */}
      <section className="recently-project-style2-area" id="recent">
        <div className="container">
        <div className="left ">
                <div className="subtext">
                  <h2>Wide Range</h2>
                </div>
                <div className="hometext2">
                  <h2>Products</h2>
                
               
              </div>
            </div>
          <div className="row pt-lg-5 pt-2">
            <div className="col-xl-12">
              <Swiper
                spaceBetween={30}
                // centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
                breakpoints={{
                  570: {
                    slidesPerView: 1,
                    spaceBetween: 70,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
                className="projSwiper"
              >
                <SwiperSlide>
                  <Link to="/Projects">
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={gnat} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                          
                          <h3>Gnat 25 series </h3>
                          <span>(25mmx25mm)</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  {/* <Link to="/Projects"> */}
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={luna} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                     
                          <h3>Luna 45 series</h3>
                          <span> (45mmx20mm)</span>
                        </div>
                      </div>
                    </div>
                  {/* </Link> */}
                </SwiperSlide>

                <SwiperSlide>
                  <Link to="/Projects">
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={apex} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                          {/* <span>Keva Site </span> */}
                          <h3>Apex 45 premium series</h3>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  {/* <Link to="/Projects"> */}
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={phoenix1} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                        
                          <h3>Phoenix 75 series</h3>
                          <span>(75mmx20mm)</span>
                        </div>
                      </div>
                    </div>
                  {/* </Link> */}
                </SwiperSlide>
                <SwiperSlide>
                  {/* <Link to="/Projects"> */}
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={cosmic} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                         
                          <h3>Cosmic 102 series</h3>
                          <span>(102mmx20mm)</span>
                        </div>
                      </div>
                    </div>
                  {/* </Link> */}
                </SwiperSlide>
                <SwiperSlide>
                  <Link to="/Projects">
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={phoenixultra} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                          {/* <span>J B Nagar</span> */}
                          <h3>Phoenix altra 80 series DG</h3>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  {/* <Link to="/Projects"> */}
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={phoenix} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                       
                          <h3>Alpha altra Maxx 100 series</h3>
                          <span>(100mmx25mm)</span>
                        </div>
                      </div>
                    </div>
                  {/* </Link> */}
                </SwiperSlide>
                
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className=" text-center pb-5" id="defaultProgressbar">
        <div className="container">
          

                <div className="row">
                    
                    <div className=" col-lg-6 mt-5 ">
                      <div className="left ">
                      <div className="subtext ">
        <h2>Our Skills</h2>
      </div>
        <div className="hometext2 ">
        <h2>The core Company Values</h2>
        <p className="mt-4"> We are committed to unwavering integrity, conducting our business with honesty and transparency in every interaction. Our dedication to quality excellence ensures that each glass partition we create reflects precision craftsmanship and premium materials. We thrive on innovation, embracing cutting-edge technologies and design trends to provide our clients with forward-thinking solutions that redefine modern workspaces.

                                        </p> 
          
        </div>
                      
                    
                                        <div className="progress-circle">
                            <div className="progress blue">
                                <span className="progress-left">
                                    <span className="progress-bar"></span>
                                </span>
                                <span className="progress-right">
                                    <span className="progress-bar"></span>
                                </span>
                                <div className="progress-value">
                                    <span className="value">88<b>%</b></span>
                                    <span className="text">More Power behind<br/>every pixel</span>
                                </div>
                            </div>
                            
                            {/* <div className="progresstext">
                          
                                <h4>Record Breaking in History</h4>
                                <p className="">Creating with Soham Enterprises is an easier, faster way to get things done.
                                    And it makes development even more effortless.</p>
                            </div> */}
                        </div>
                                        </div>

                        <div className="about box-white text-left">
                      
                            <div className="skills mt-40 ">
                                <div className="skill-item ">
                                    <h6>Our Progress</h6>
                                    <div className="skill-progress">
                                        <div className="progress2 progress-moved">
                                            <div className="progress-bar2"></div>
                                            <div className="loader" ></div>
                                        </div>
                                    </div>
                                    <div className="skill-item ">
                                        <h6>Worker Speed</h6>
                                        <div className="skill-progress">
                                            <div className="progress2 progress-moved">
                                                <div className="progress-bar2"></div>
                                                <div className="loader" ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="skill-item">
                                        <h6>Team Performance </h6>
                                        <div className="skill-progress">
                                            <div className="progress2 progress-moved">
                                                <div className="progress-bar2"></div>
                                                <div className="loader" ></div>
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div className="col-lg-6 col-md-12 order-lg-2  order-1 p-5">
        <div className="img ">
          <img
          className='img-fluid'
            src={abt}
            alt=""
          />
        </div>
      </div>
                </div>
        </div>

    </section>
    </div>
  )
}

export default Products