import React, { useState } from 'react';
import "../Components/NavBar.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiFillInstagram } from 'react-icons/ai';
import { FaFacebookSquare } from 'react-icons/fa';
import { BsWhatsapp } from 'react-icons/bs';
import { BiLogoLinkedin } from 'react-icons/bi';
import Site from "../Components/Site";
import Alphaprod from './Alphaprod';
import Logo from "../img/Untitled design (57) (1).png";
const Header = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
      setIsOpen(!isOpen);
    };
    const [color, setColor] = useState(false)

    const changeColor = () => {
        if (window.scrollY >=100) {
            setColor(true)
        } else {
            setColor(false)
        }
    }

    window.addEventListener('scroll', changeColor)


    window.addEventListener("scroll", changeColor);
    // popup Modal
    // const [modalShow, setModalShow] = React.useState(false);

    // const [expanded, setExpanded] = React.useState("panel1");

    // const handleChange = (panel) => (event, isExpanded) => {
    //     setExpanded(isExpanded ? panel : false);
    // };


    return (
        <>
            <div className="nav-bar-component">
                <div className="NavSection">
                    {/* <div className="container"> */}
                    <Navbar collapseOnSelect expand="lg" variant="dark" className={color ? "NavBar NavBar-bg" : "NavBar"}>
                        <Container>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={toggleNavbar}/>
                             <Navbar.Brand>
                                <Link to="/">
                                 
                                        <img src={Logo} alt="" />
                                   
                                </Link>
                            </Navbar.Brand> 
                            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-start">
                                <Nav className={`navbar-links ${isOpen ? 'open' : ''}`}>
                                    <Nav.Link href="/"><Link to="/">Home</Link></Nav.Link>
                                    <Nav.Link href="/best-glass-partition-services-thane"><Link to="/best-glass-partition-services-thane">About</Link></Nav.Link>
                                    {/* <Nav.Link href="#About us"><Link to="/Project">Project</Link></Nav.Link> */}
                                   
                                      <button className="dropdownShow" id=""> Products
                                      

                                        <div className="dorpdown">
                                        <div className="dropdown_list">
                                         
                                                {Site.map((elm,i)=>{
                                                    const { Title , Slugs} =elm
                                                    return(
                                                        <>
                                                        <Nav.Link href="/office">

                                                        {/* <Link to={`/${Slugs}`}>{Title}</Link> */}
                                                            <Link to={Slugs} > {Title}</Link>

                                                        </Nav.Link>
                                                        </>
                                                    )
                                                })}
                                                 {Alphaprod.map((elm,i)=>{
                                                    const { Title , Slugs} =elm
                                                    return(
                                                        <>
                                                        <Nav.Link href="/office">

                                                        {/* <Link to={`/${Slugs}`}>{Title}</Link> */}
                                                            <Link to={Slugs} > {Title}</Link>

                                                        </Nav.Link>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                       
                                        </div>
                                       
                                    </button>

                                    {/* <Nav.Link href="#Service"><Link to="/Service">Service</Link></Nav.Link> */}
                                    <Nav.Link href="/frameless-glass-partition-services-thane"><Link to="/frameless-glass-partition-services-thane">Gallery</Link></Nav.Link>
                                    <Nav.Link href="/demountable-glass-partition-near-thane"><Link to="/demountable-glass-partition-near-thane">Contact</Link></Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                            <Nav className="Social-media-icons">
                                <Nav.Link href='https://www.instagram.com/shreejipartitionsystem/' target="_blank"><AiFillInstagram /></Nav.Link>
                                <Nav.Link href='https://www.facebook.com/shreejipartitionsystem/' target="_blank"><FaFacebookSquare /></Nav.Link>
                                {/* <Nav.Link href='https://www.linkedin.com/company' target="_blank"><BiLogoLinkedin /></Nav.Link> */}
                                <Nav.Link href='https://wa.me/+918433542937?text=' target="_blank"><BsWhatsapp /></Nav.Link>
                            </Nav>
                        </Container>
                    </Navbar>
                    {/* </div> */}
                </div>
            </div>
        </>
    )
}

export default Header