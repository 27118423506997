import sg1 from "../img/singleglazepartition/WhatsApp Image 2024-01-04 at 10.55.06 PM.webp"
import sg2 from "../img/singleglazepartition/WhatsApp Image 2024-01-04 at 10.55.12 PM.webp"
import sg3 from "../img/singleglazepartition/WhatsApp Image 2024-01-04 at 10.55.13 PM (1).webp"
import sg4 from "../img/singleglazepartition/WhatsApp Image 2024-01-04 at 10.55.13 PM (2).webp"
import sg5 from "../img/singleglazepartition/WhatsApp Image 2024-01-04 at 10.55.13 PM.webp"
import sg6 from "../img/singleglazepartition/WhatsApp Image 2024-01-04 at 10.55.14 PM (1).webp"
import dg1 from "../img/double glaze partition/WhatsApp Image 2024-01-04 at 10.57.26 PM.webp"
import dg2 from "../img/double glaze partition/WhatsApp Image 2024-01-04 at 10.58.44 PM.webp"
import dg3 from "../img/double glaze partition/WhatsApp Image 2024-01-04 at 10.58.47 PM.webp"
import dg4 from "../img/double glaze partition/WhatsApp Image 2024-01-04 at 10.58.48 PM (1).webp"
import dg5 from "../img/double glaze partition/WhatsApp Image 2024-01-04 at 10.58.48 PM (1).webp"
import dg6 from "../img/double glaze partition/WhatsApp Image 2024-01-04 at 10.58.49 PM.webp"
const GalleryData = [
  {
    id: 1,
    Category:"Single Glazed Partition",
    CategoryName:"Single Glazed Partition",
    CImage:sg1,
    alt:"Single Glazed Partition",
  },
  {
    id: 1,
    Category:"Single Glazed Partition",
    CategoryName:"Single Glazed Partition",
    CImage:sg2,
    alt:"Single Glazed Partition",
  },
  {
    id: 1,
    Category:"Single Glazed Partition",
    CategoryName:"Single Glazed Partition",
    CImage:sg3,
    alt:"Single Glazed Partition",
  },
  {
    id: 1,
    Category:"Single Glazed Partition",
    CategoryName:"Single Glazed Partition",
    CImage:sg4,
    alt:"Single Glazed Partition",
  },
  {
    id: 1,
    Category:"Single Glazed Partition",
    CategoryName:"Single Glazed Partition",
    CImage:sg5,
    alt:"Single Glazed Partition",
  },
  {
    id: 1,
    Category:"Single Glazed Partition",
    CategoryName:"Single Glazed Partition",
    CImage:sg6,
    alt:"Single Glazed Partition",
  },
  
  {
    id: 2,
    Category:"Double Glazed Partition",
    CategoryName:"Birthday",
    CImage:dg1,
    alt:"Double Glazed Partition",
  },
  {
    id: 2,
    Category:"Double Glazed Partition",
    CategoryName:"Birthday",
    CImage:dg2,
    alt:"Double Glazed Partition",
  },
  {
    id: 2,
    Category:"Double Glazed Partition",
    CategoryName:"Birthday",
    CImage:dg3,
    alt:"Double Glazed Partition",
  },
  {
    id: 2,
    Category:"Double Glazed Partition",
    CategoryName:"Birthday",
    CImage:dg4,
    alt:"Double Glazed Partition",
  },
  {
    id: 2,
    Category:"Double Glazed Partition",
    CategoryName:"Birthday",
    CImage:dg5,
    alt:"Double Glazed Partition",
  },
  {
    id: 2,
    Category:"Double Glazed Partition",
    CategoryName:"Birthday",
    CImage:dg6,
    alt:"Double Glazed Partition",
  },
];

export default GalleryData;

