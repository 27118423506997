import Deloitte1 from '../Components/Banner/img/Untitled-1.jpg';

import apex from "../img/products/apex.webp";

import phoenix from "../img/products/phoenix.webp";

import alpha1 from "../img/alpha-altra.webp";
import apex1 from "../img/apex-45.webp";
const Alphaprod =[
    {
        id:1,
        Title:"Alpha Altra Maxx 100",
        Pspec:"(100mm/25mm Double Glaze Partition System)",
        Desc:"Alpha Altra Maxx 100, wide line design system providing full height partition in Double glaze system giving high acoustic performance.",
        EImage1:phoenix,
        EImage2:alpha1,
        Eimage3:Deloitte1,
        Slugs:"/alpha-altra-maxx-100",
        C1:"10mm & 12mm Clear toughened or 10.76, 11.10 & 11.52 Clear laminated glass.",
        C2:"Double Glazed Partition",
        C3:"Partition height can be considered upto 3000 mm.",
        C4:"All exposed aluminum sections are Anodized and come in 6063 with T5 Grade.",
        Door:" Doors can be mounted either on Hinges, patch systems or Pivot doors for frameless glass doors, Stile doors also can be incorporated in 65 series and 75 series both.",
        Compatible:"Compatible to glass thickness :",
        Shades:"Silver Anodized / Mid Night Black /(Mill finish also available, Anodizing shades or powder coating subject to test on  profiles min qty 500 sq ft)",
        Capacity:" 44 dB to 48 dB.",
        MetaTitle: "Alpha Altra Maxx 100 | Shree Ji",
        MetaDes:  "Upgrade your space with Alpha Altra Maxx 100 Glass. Maximized clarity, architectural elegance, and sustainable brilliance – redefine your surroundings today",
        CanonicalTag: "https://shreejipartitions.in/alpha-altra-maxx-100",
    },
    {
        id:2,
        Title:"Apex 45 premium",
        Pspec:"(45mm/25mm Single Glaze Partition System)",
        Desc:"Apex 45 premium, provides Slim line design system providing full height partition as well as block design adaptability.",
        EImage1:apex,
        EImage2:apex1,
        Eimage3:Deloitte1,
        Slugs:"/apex-45-premium",
      
        C1:"10mm & 12mm clear toughened or 10.76, 11.10 & 11.52 Clear laminated glass.        ",
        C2:"Single Glazed Partition ( Blck Design Compatible in Divider system or Stick-On system)",
        C3:"Partition height can be considered up to 3000 mm.",
        C4:"All exposed aluminum sections are Anodized and come in 6063 with T5 Grade.",
        Door:" Doors can be mounted either on Hinges, patch systems or Pivot doors for frameless glass doors,Stile doors also can be incorporated.",
        Compatible:"Compatible to glass thickness :",
        Shades:"Silver Anodized / Mid Night Black /(Mill finish also available, Anodizing shades or powder-coating subject to test on profiles min qty 500 sq ft)",
        Capacity:" 32 dB to 34 dB",
        MetaTitle: "Apex 45 Premium | Shree Ji ",
        MetaDes:  "Step into a world of crystal-clear precision with the Apex 45 Premium Glass. These glass panels redefine your visual experience.",
        CanonicalTag: "https://shreejipartitions.in/apex-45-premium",
    },
];

export default Alphaprod;
