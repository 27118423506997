import React from 'react'
import {Link} from "react-router-dom";
import Footer from "../Components/Footer";
const ErrorPage = () => {
  return (
    <div>
           <div className="AboutUs-Section-1">
        {/* <Header /> */}
        <div className="AboutUs-Section-Banner">
          <div className="Aboutus-banner-text">
            <h1>404<span className="d-none">Best Glass Partition services in Thane</span></h1>
            
          </div>
        </div>
      </div>
      {/* =========== About Section =========== */}
      <section className="about-section">
        <div className="container">
          
          <div className="row">
          
          <h2>Back to <Link to="/">Home</Link></h2>
            <div className="left">
      <div className="subtext">
        <h2>About Company</h2>
      </div>
        <div className="hometext2">
        
          <h2 className="">Modern Partitions</h2>
          <p>
          Welcome to Shreeji partition, a best glass partition service in Thane, a distinguished leader in the glass partition industry. With a relentless commitment to excellence, we bring a wealth of expertise in designing and installing premium glass partitions for a myriad of environments. As we are the best glass partition service in Thane we understand that impeccable design is as crucial as functionality. Our team of seasoned professionals combines technical prowess with an acute sense of aesthetics, delivering bespoke glass solutions tailored to meet the unique needs of each client.We take pride in transforming spaces, be it corporate offices, healthcare facilities, or residential dwellings, into harmonious environments where transparency harmonizes seamlessly with privacy.

          </p>
          <p></p>
          
        </div>
        </div>
         
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  )
}

export default ErrorPage