import logo from './logo.svg';
import './App.css';
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./Pages/Home";
import Header from './Components/Header';
import Aboutus from './Components/AboutUs';
import Details from './Components/Details';
import Alphaprod  from './Components/Alphaprod';
import Product2 from './Components/Product2';
import ErrorPage from './Components/ErrorPage';
// import Royalfarm from './pages/Royalfarm';
import Contact from './Components/Contact';
import Site from "./Components/Site"
import Product from './Components/Product';
import Gallery from "./Pages/Gallery"
function App() {
  return (
    <BrowserRouter>
    <Header/>
      <Routes>
        <Route path='/' element={<Home/>}/> 
         <Route path='/best-glass-partition-services-thane' element={<Aboutus/>} />
         <Route path='/customized-glass-partition-services-thane' element={<Details/>} />
         <Route path='/frameless-glass-partition-services-thane' element={<Gallery/>} />
         {Site.map((elm,i)=>{
          const {Slugs} = elm
          return(<>
            <Route path={Slugs}  element={<Product />} />
          </>)
         })}
          {Alphaprod.map((elm,i)=>{
          const {Slugs} = elm
          return(<>
            <Route path={Slugs}  element={<Product2/>} />
          </>)
         })}
         {/* {Site.map((route, index) => (
          <Route
            key={index}
            exact
            path={route.Slugs}
            element={<Details/>}
          />
        ))} */}
        
         {/* <Route path='/Doctors' element={<Doctors/>} /> */}
         {/* <Route path='/decorator-near-malad' element={<Gallery/>} /> */}
         <Route path='/demountable-glass-partition-near-thane' element={<Contact/>} /> 
         <Route path='/*' element={<ErrorPage/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
