import Deloitte1 from '../Components/Banner/img/Untitled-1.jpg';
import gnat from "../img/products/gnat.webp";
import cosmic from "../img/cosmic.webp";

import luna from "../img/products/luna.webp";
import phoenix from "../img/products/phoenix.webp";
import gnat1 from "../img/gnat-25.webp";
import luna1 from "../img/luna-45.webp";
import phoenix1 from "../img/phoenix (2).webp";
import phoenixultra from "../img/phoenixa-ltra.webp";
import cosmic1 from "../img/cosmic.webp";

const Site = [

   
    {
        id:1,
        Title:"Gnat 25 series",
        Slugs:"/gnat-25-series-installation-thane",
        Pspec:"25mm (Single Glaze Partition System)",
        Desc:"The Pint demountable office partition system can be equipped with strong acoustic insulation for more relaxing atmosphere within the large office space. It can be used with glass to glass dry joints including flush aluminium.",
        EImage1:gnat,
        EImage2:gnat1,
        Eimage3:Deloitte1,
        // EventName:"Deloitte",
     
        Material:"Aluminium",
        Surface:"Anodized/Powder Coated/Brush/SS Finish",
        Length:"2600 mm/3600 mm",
        Standard:"25 x 22 mm",
        Gauge:"2 mm (approx)",
        CoatingThickness:"Anodized 15-20 Microns (Avg.).  Powder Coated 50-70 Microns (Avg.)",
        Installation:"Screw Fixing",
        Track1:"Standard track section : 25mm x 22mm ",
        Track2:"Slim in design, the standard partition suite can be used with any stud thickness.",
        Track3:"Comfortable glass thickness of 10mm to 12mm.",
        Track4:"Single glazed system.",
        Track5:"Sound insulation capacity of 32 - 36 dB.",
        // Track6:"Sound Insulation capacityof 38-42 dB.",
        MetaTitle: "Gnat 25 Series installation in Thane | Shree ji ",
        MetaDes: "Discover top-notch Gnat 25 Series Installation Services in Thane. Our experts ensure seamless installations for reliable performance. ",
        CanonicalTag: "https://shreejipartitions.in/gnat-25-series-installation-thane",
    },
    {
        id:2,
        Title:"Luna",
        Pspec:"45mm (Single Glaze Partition System).",
        Desc:"The Premium demountable office partition is a superior lightweight profile system. It is a based on a 45 mm x 20 mm glazing seal and bead with a selection of door frame options. Premium frame provides an elegant and cost effective design solution.",
        EImage1:luna,
        EImage2:luna1,
        Eimage3:Deloitte1,
        // EventName:"Deloitte",
        Slugs:"/luna-45mm-demountable-partition",
        Material:"Aluminium",
        Surface:"Anodized/Powder Coated/Brush/SS Finish",
        Length:"2600 mm/3600 mm",
        Standard:"45 x 20 mm",
        Gauge:"2 mm (approx)",
        CoatingThickness:"Anodized 15-20 Microns (Avg.). Powder Coated 50-70 Microns (Avg.).",
        Installation:"Screw Fixing",
        Track1:"Standard track section : 45mm x 20mm ",
        Track2:"Slim in design, the standard partition suite can be used with any stud thickness.",
        Track3:"Comfortable glass thickness of 10mm to 12mm.",
        Track4:"Single door frame size to allow for flush door of 40mm-42mm.",
        Track5:"Single glazed system.",
        Track6:"Sound insulation capacity of 28 - 32 dB.",
        // Track6:"Sound Insulation capacityof 38-42 dB.",
        MetaTitle: "Single Glazed Partition System | Shree Ji",
        MetaDes: "iscover the perfect blend of modern aesthetics and functionality with our Single Glazed Partition System. Transform your space seamlessly.",
        CanonicalTag: "https://shreejipartitions.in/luna-45mm-demountable-partition",
    },
     
   
    {
        id:4,
        Title:"Phoenix 75 series ",
        Slugs:"/phoenix-75-series",
        Pspec:"75mm (Single Glaze Partition System).",
        Desc:"The Ultra demountable office partition is another superior range of Empire products that has a proven track record. It is a based on a 75mm x 20mm glazing seal and bead with a selection of door frame options.",
        EImage1:phoenix,
        EImage2:phoenix1,
        Eimage3:Deloitte1,
        // EventName:"Deloitte",
     
        Material:"Aluminium",
        Surface:"Anodized/Powder Coated/Brush/SS Finish",
        Length:"2600 mm/3600 mm",
        Standard:"75 x 20 mm",
        Gauge:"2 mm (approx)",
        CoatingThickness:"Anodized 15-20 Microns (Avg.) Powder Coated 50-70 Microns (Avg.)",
        Installation:"Screw Fixing",
        Track1:"Standard track section : 75mm x 20mm ",
        Track2:"Slim in design, the standard partition suite can be used with any stud thickness.",
        Track3:"Comfortable glass thickness of 10mm to 12mm.",
        Track4:"Single door frame size to allow for flush door of 40mm or 42mm.",
        Track5:"Single glazed system.",
        Track6:"Sound insulation capacity of 32 - 34 dB.",
        // Track6:"Sound Insulation capacityof 38-42 dB.",
        MetaTitle: "Phoenix 75 Series | Shree Ji",
        MetaDes: "Elevate your space with a touch of modern sophistication. The Phoenix 75 Series Glass isn't just about durability it's a statement of style. ",
        CanonicalTag: "https://shreejipartitions.in/phoenix-75-series",
    },
    {
        id:5,
        Title:"Cosmic 102 Series",
        Slugs:"/cosmic-102-series",
        Pspec:"102mm (Single Glaze Partition System).",
        Desc:"The Ultra-Strong demountable office partition is another superior range of Empire products that has a proven track record. It is a based on a 102mm x 20mm glazing seal and bead with a selection of door frame options.",
        EImage1:cosmic,
        EImage2:cosmic1,
        Eimage3:Deloitte1,
        // EventName:"Deloitte",
     
        Material:"Aluminium",
        Surface:"Anodized/Powder Coated/Brush/SS Finish",
        Length:"2600 mm/3600 mm",
        Standard:"102 x 20 mm",
        Gauge:"2 mm (approx)",
        CoatingThickness:"Anodized 15-20 Microns (Avg.) Powder Coated 50-70 Microns (Avg.)",
        Installation:"Screw Fixing",
        Track1:"Standard track section 102mm x 20mm ",
        Track2:"Slim in design, the standard partition suite can be used with any stud thickness.",
        Track3:"Comfortable glass thickness of 10mm to 12mm.",
        Track4:"Single door frame size to allow for flush door of 40mm or 42mm.",
        Track5:"Single glazed system.",
        Track6:"Sound insulation capacity of 32 - 34 dB.",
        // Track6:"Sound Insulation capacityof 38-42 dB.",
        MetaTitle: "Cosmic 102 Series | Shree Ji ",
        MetaDes:  "Achieve a seamless blend of modern aesthetics and cosmic allure with the Cosmic 102 Series Glass.",
        CanonicalTag: "https://shreejipartitions.in/cosmic-102-series",
    },
    
    

 
    
    {
        id:1,
        Title:"Phoenix Altra 80 series",
        Pspec:"(80mm/100mm Double Glaze Partition System)",
        Desc:"The Ultra Max demountable office partition provides excellent acoustic values. It is based on a 80 x 30 mm glazing seal and bead with a selection of door frame options.",
        EImage1:phoenix,
        EImage2:phoenixultra,
        Eimage3:Deloitte1,
        Slugs:"/phoenix-altra-80-series",
        // EventName:"Deloitte",
     
        Material:"Aluminium",
        Surface:"Anodized/Powder Coated/Brush/SS Finish",
        Length:" 3000 mm",
        Standard:"80 x 30 mm",
        Gauge:"2 mm (approx)",
        Track1:"80mm & 30mm ",
        Track2:"Slim in design,the standard partition suite can be used with any stud thickness",
        Track3:"Comfortable glass thickness of 10 mm.",
        Track4:" Partition height offered up to 3000 mm.",
        Track5:"Double glazed system",
        Track6:"Sound Insulation capacityof 38-42 dB.",
        MetaTitle: "Phoenix Altra 80 Series | Shree Ji",
        MetaDes: "Transform your space into a work of art with the seamless integration of the Phoenix Altra 80 Series Glass.",
        CanonicalTag: "https://shreejipartitions.in/phoenix-altra-80-series",
    },
     
    
]



export default Site;