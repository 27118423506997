import { React, useLayoutEffect,useState } from "react";
import { useLocation,useParams } from "react-router-dom";
import Footer from "./Footer";
import "../Components/Doctors.css";
import abt from "../Components/Banner/img/c3.jpg";
import Site from "./Site";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import gnat from "../Components/Banner/img/gnat.webp";
import cosmic from "../Components/Banner/img/cosmic.jpg";
import phoenix1 from "../Components/Banner/img/phoenix75.jpeg";
import phoenixultra from "../Components/Banner/img//phoenix altra.jpeg";
import luna from "../Components/Banner/img/luna.webp";
import phoenix from "../Components/Banner/img/phoenix.webp";
import apex from "../Components/Banner/img/apex.webp";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Nielsen from "../Components/Banner/img/Untitled-1.jpg";
import Alphaprod from "./Alphaprod";

const Product2 = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const search = useLocation();
  const Path = search.pathname;
  const FData = Alphaprod.find((e) => e.Slugs == Path);
  // console.log(CourseDesc);
  return (
    <div>
     
      {/* ----------------------------seo--------------------------------------- */}
      {/* =========== Banner section ========= */}
      <div className="AboutUs-Section-1">
        {/* <Header /> */}
        <div className="AboutUs-Section-Banner">
          <div className="Aboutus-banner-text">
            <h1>{FData.Title}</h1>
            <div className="Aboutus-banner-text-page">
              <Link to="/">Home</Link>
              <h2>/</h2>
              <h2>Products</h2>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- ===================================  text with  images =================================== --> */}
      <section className="pt-5">
        <div className="container">
          <div className="row">
         
      
        {/* {Site.map((elem) => {
              const { id, Category,Pspec,
              Desc, Material, Surface,Length,Standard, Gauge,Track1,
              Track2,
              Track3,
              Track4,
              Track5,
              Track6,
               } = elem;
              return (
                <> */}
            <div className="col-lg-6 col-md-12" >
              <div className="prodImg">
                <img className="img-fluid" src={FData.EImage1} alt="office flooring" />
              </div>
            </div>
            <div className="col-lg-6" >
              <div className="left">
                <div className="subtext">
                  <h2>Product</h2>
                </div>
                <div className="hometext2">
                  <h2 className="">{FData.Title}</h2>
<span >{FData.Pspec}</span><br/>
                  <p>{FData.Desc}
                  
                  </p>
                </div>
                
              </div>
             
            </div>
            <div className=" col-12 mt-lg-5 mt-2">
            <div class="row rowspace">
                {/* <div class="col-sm-4 ">
                       
                    </div> */}
                
                
                <div class="col-12">
                  <div class="Specialty">
                    <span>
                      <b>Compatibility: &nbsp;</b>
                      </span>
                      <ul>
                        <li>

{FData.C1}
</li>
                        <li>{FData.C2}</li>
                        <li>{FData.C3}</li>
                        <li>{FData.C4}</li>
                      </ul>
                    
                    <span class="description">
                 {FData.Compatinble}
                    </span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="Specialty">
                    <span>
                      <b>Door Frames: &nbsp;</b>
                    </span>
                    <span class="description">
                 {FData.Door}
                    </span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="Specialty">
                    <span>
                      <b>Shades: &nbsp;</b>
                    </span>
                    <span class="description">
                 {FData.Shades}
                    </span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="Specialty">
                    <span>
                      <b>Capacity: &nbsp;</b>
                    </span>
                    <span class="description">
                 {FData.Capacity}
                    </span>
                  </div>
                </div>
              </div>
              </div>
        
     
{/* 
       
            </>
        );
      })} */}
<section className="recently-project-style2-area" id="recent">
        <div className="container">
        <div className="left ">
                <div className="subtext">
                  <h2>Wide Range</h2>
                </div>
                <div className="hometext2">
                  <h2>Products</h2>
                
               
              </div>
            </div>
          <div className="row pt-lg-5 pt-2">
            <div className="col-xl-12">
              <Swiper
                spaceBetween={30}
                // centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
                breakpoints={{
                  570: {
                    slidesPerView: 1,
                    spaceBetween: 70,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
                className="projSwiper"
              >
                <SwiperSlide>
                  <Link to="/Projects">
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={gnat} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                          
                          <h3>Gnat 25 series </h3>
                          <span>(25mmx25mm)</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  {/* <Link to="/Projects"> */}
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={luna} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                     
                          <h3>Luna 45 series</h3>
                          <span> (45mmx20mm)</span>
                        </div>
                      </div>
                    </div>
                  {/* </Link> */}
                </SwiperSlide>

                <SwiperSlide>
                  <Link to="/Projects">
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={apex} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                          {/* <span>Keva Site </span> */}
                          <h3>Apex 45 premium series</h3>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  {/* <Link to="/Projects"> */}
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={phoenix1} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                        
                          <h3>Phoenix 75 series</h3>
                          <span>(75mmx20mm)</span>
                        </div>
                      </div>
                    </div>
                  {/* </Link> */}
                </SwiperSlide>
                <SwiperSlide>
                  {/* <Link to="/Projects"> */}
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={cosmic} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                         
                          <h3>Cosmic 102 series</h3>
                          <span>(102mmx20mm)</span>
                        </div>
                      </div>
                    </div>
                  {/* </Link> */}
                </SwiperSlide>
                <SwiperSlide>
                  <Link to="/Projects">
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={phoenixultra} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                          {/* <span>J B Nagar</span> */}
                          <h3>Phoenix altra 80 series DG</h3>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  {/* <Link to="/Projects"> */}
                    <div className="single-project-style2">
                      <div className="img-holder">
                        <img src={phoenix} alt="" />
                        <div className="read-more">
                          <a href="Service.jsx">
                            <span className="icon-next" />
                          </a>
                        </div>
                        <div className="title-box" style={{background:"black", padding:10}}>
                       
                          <h3>Alpha altra Maxx 100 series</h3>
                          <span>(100mmx25mm)</span>
                        </div>
                      </div>
                    </div>
                  {/* </Link> */}
                </SwiperSlide>
                
              </Swiper>
            </div>
          </div>
        </div>
      </section>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Product2